<template>
  <div class="row">
    <div class="col-md-12 mt-2">
      <div class="card">
        <h2 class="title text-center pt-1 mt-1">FINANCE REPORT</h2>
        <br />
        <div class="col-md-12 pt-2">
          <div class="form-group row">
            <span class="col-sm-2">Periode</span>
            <div class="col-md-auto">
              <v-date-picker
                v-model="range"
                mode="date"
                :masks="masks"
                is-range
              >
                <template v-slot="{ inputValue, inputEvents, isDragging }">
                  <div id="betweenPeriod" class="rows">
                    <input
                      id="startPeriod"
                      class="flex-grow pl-6 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                      :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                      :value="inputValue.start"
                      v-on="inputEvents.start"
                    />
                    <span class="p-2">to</span>
                    <input
                      id="endPeriod"
                      class="flex-grow pl-6 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                      :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                      :value="inputValue.end"
                      v-on="inputEvents.end"
                      :max-date="new Date()"
                    />
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group row">
            <span class="col-sm-2">Offer Type</span>
            <div class="col-md-auto">
              <b-form-select
                id="searchOfferMode"
                class="flex-grow"
                v-model="offerType"
                track-by="value"
                :options="offerTypeOptions"
                required
              >
              </b-form-select>
            </div>
          </div>
          <div class="form-group row">
            <span class="col-sm-2">Publisher</span>
            <div class="col-md-4">
              <multiselect
                v-model="vendor"
                label="name"
                track-by="name"
                placeholder="type to find vendor..."
                open-direction="bottom"
                :options="items_vendor"
                :searchable="true"
                :loading="isLoadingSearch"
                :multiple="true"
                @search-change="findVendorName"
              >
                <span slot="noResult">
                  Oops! No elements found. Consider changing the search query.
                </span>
              </multiselect>
            </div>
            <div class="col-md-1">
              <button class="btn btn-block btn-primary" @click="btnSearch">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
        <b-row v-if="isError" class="justify-content-center mt-2 mx-3">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong
            </b-alert>
          </b-col>
        </b-row>
        <div class="card-body">
          <div class="row justify-content-end">
            <label for="" class="col-sm-auto">Total : </label>
            <strong class="col-sm-auto">{{ formatNumber(totalRows) }}</strong>
          </div>
          <b-table
            sticky-header="500px"
            :items="items"
            :fields="fields"
            :busy="isLoading"
            :isLoading="isLoading"
            show-empty
            responsive="xl"
            hover
            sort-icon-left
          >
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1) }}.
            </template>

            <template #cell(name)="row">
              <div style="width: 250px">
                {{ row.item.name ? row.item.name : '-' }}
              </div>
            </template>

            <template #cell(disbursement_period)="row">
              <div style="width: 100px">
                {{
                  row.item.disbursement_period
                    ? row.item.disbursement_period
                    : '-'
                }}
              </div>
            </template>

            <template #cell(offer_type)="row">
              <div v-if="row.item?.offer_type == 'single'">
                <span class="badge badge-primary">{{
                  row.item?.offer_type
                }}</span>
              </div>
              <div v-if="row.item?.offer_type == 'bundle'">
                <span class="badge badge-yellow">{{
                  row.item?.offer_type
                }}</span>
              </div>
              <div v-if="row.item?.offer_type == 'subscription'">
                <span class="badge badge-danger">{{
                  row.item?.offer_type
                }}</span>
              </div>
              <div v-if="row.item?.offer_type == 'buffet'">
                <span class="badge badge-purple">{{
                  row.item?.offer_type
                }}</span>
              </div>
            </template>

            <template #cell(vendor)="row">
              <div style="width: 250px">
                <div v-if="row.item.vendor">
                  {{ row.item.vendor?.name }}
                </div>
                <div v-else>
                  <router-link
                    :to="{ path: '/vendor/edit/' + row.item?.vendor_id }"
                    target="_blank"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Just update vendor to sync with database warehouse"
                  >
                    <span class="badge badge-warning">
                      <i class="fa fa-warning"></i> update vendor</span
                    >
                  </router-link>
                </div>
              </div>
            </template>

            <template #cell(is_active)="row">
              <span
                v-if="row.item.is_active"
                class="btn badge badge-primary"
                data-toggle="tooltip"
                data-placement="top"
                title="Click to update status"
                @click="editStatus(row.item.is_active, row.item.id)"
              >
                Active
              </span>
              <span
                v-else
                class="btn badge badge-danger"
                data-toggle="tooltip"
                data-placement="top"
                title="Click to update status"
                @click="editStatus(row.item.is_active, row.item.id)"
                >Inactive</span
              >
            </template>

            <template #cell(role_allowed)="row">
              <div style="width: 100px">
                {{ row.item.role_allowed ? row.item.role_allowed : '-' }}
              </div>
            </template>

            <template #cell(created)="row">
              <div style="width: 250px">
                {{ row.item.created ? formatDate(row.item.created) : '-' }}
              </div>
            </template>

            <template #cell(action)="row">
              <div class="d-flex">
                <b-button variant="danger" @click="onGenerateInv(row.item)">Generate Inv!</b-button>
              </div>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner
                  class="align-middle"
                  variant="dark"
                  type="grow"
                  label="Loading .."
                >
                </b-spinner>
                Loading ..
              </div>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import constant from '../../../store/constant';
export default {
  name: 'ListFinanceReport',
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      fields: [
        { key: 'No', label: 'No.' },
        { key: 'vendor', label: 'Publisher' },
        { key: 'disbursement_period', label: 'Periode' },
        { key: 'offer_type', label: 'Offer Type' },
        { key: 'name', label: 'Name' },
        { key: 'is_active', label: 'Status' },
        { key: 'role_allowed', label: 'Role Allowed' },
        { key: 'created', label: 'Created' },
        { key: 'action' },
      ],
      range: {
        start: moment()
          .startOf('month')
          .subtract(1, 'M')
          .format('YYYY-MM-DD'),
        end: moment()
          .subtract(1, 'M')
          .endOf('month')
          .format('YYYY-MM-DD'),
      },
      masks: {
        input: 'YYYY-MM-DD',
      },
      offerType: '',
      offerTypeOptions: [
        { value: '', text: 'All Offer' },
        { value: constant.OFFER_TYPE_NAME.SINGLE, text: 'Single' },
        { value: constant.OFFER_TYPE_NAME.BUNDLE, text: 'Bundle' },
        {
          value: constant.OFFER_TYPE_NAME.SUBSCRIPTIONS,
          text: 'Subscriptions',
        },
        { value: constant.OFFER_TYPE_NAME.BUFFET, text: 'Buffet' },
      ],
      vendor: [],
      items_vendor: [],
      isLoadingSearch: false,
    };
  },
  watch: {
    currentPage: function() {
      this.getFinanceReport();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Finance Reports | Content Management System Ebooks Gramedia.com';
      },
    },

    items: function() {
      if (!this.items.length) return;
    },
  },

  computed: {
    ...mapState({
      isLoading: (state) => state.financeReport.isLoading,
      isError: (state) => state.financeReport.isError,
      totalRows: (state) => state.financeReport.totalRows,
      items: (state) => state.financeReport.items,
    }),
  },

  mounted() {
    this.getFinanceReport();
  },

  methods: {
    ...mapActions('financeReport', ['fetchFinanceReport', 'updateStatus', 'postGenerateInvoice']),
    ...mapActions('vendors', ['findVendor']),

    getFinanceReport() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
        startPeriode: moment(this.range.start).format('YYYY-MM-DD'),
        endPeriode: moment(this.range.end).format('YYYY-MM-DD'),
      };
      if (this.vendor.length) {
        payload.vendor_id = this.vendor
          ? this.vendor.map((val) => val?.id)
          : '';
      }
      if (this.offerType) payload.offerType = this.offerType;
      this.fetchFinanceReport(payload);
      this.setQuery(payload);
    },

    setQuery(q = {}) {
      this.$router.push({ query: q }).catch(() => {});
    },

    formatDate(tgl) {
      return new Date(tgl).toString();
    },

    formatNumber(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      return '0';
    },

    findVendorName(keyword) {
      if (keyword.length > 2) {
        this.isLoadingSearch = true;
        let payload = {
          q: keyword,
        };
        this.findVendor(payload)
          .then((resp) => {
            this.items_vendor = resp.data.data;
            this.isLoadingSearch = false;
          })
          .catch((err) => {
            this.isLoadingSearch = false;
            console.log(err);
          });
      }
    },

    btnSearch() {
      this.getFinanceReport();
    },

    editStatus(status, id) {
      this.$swal({
        title: 'Are you sure ?',
        text: `You will update status to ${status ? 'inactive' : 'active'}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Update',
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateStatus({ status, id })
            .then((res) => {
              if (res.status == 201) {
                this.$swal({
                  icon: 'success',
                  toast: true,
                  position: 'top-end',
                  title: 'Success',
                  timer: 10000,
                  text: 'Status has been successfully changed.',
                });
              }
              this.getFinanceReport();
            })
            .catch((err) => {
              if (err.response.status == 404) {
                this.$swal('Ops!', err.response.data.message, 'error');
              }
              if (err.response.status == 422) {
                err.response.data.errors
                  .reverse()
                  .map((e) => this.$swal('Ops!', e?.msg, 'error'));
              }
              if (err.response.status == 500) {
                this.$swal('Ops!', err.response.data.data.message, 'error');
              }
            });
        }
      });
    },

    async confirmGenerateInvoice(item) {
      const swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: 'btn btn-danger mx-1',
          cancelButton: 'btn btn-primary mx-1',
        },
        buttonsStyling: false,
      });
      const result = await swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: `you will generate invoice for 
          ${item?.vendor?.name || item.vendor_id} - ${item.offer_type}
          `,
        icon: 'warning',
        showCancelButton: true,
        reverseButtons: true,
      });

      return result;
    },

    async onGenerateInv(item = {}) {
      const { isConfirmed = false } = await this.confirmGenerateInvoice(item);
      if (!isConfirmed) return;

      console.log(constant.OFFER_TYPE);
      console.log(constant.OFFER_TYPE[`${item.offer_type}`.toUpperCase()]);
      console.log(`${item.offer_type}`.toUpperCase());
      
      const response = await this.postGenerateInvoice({
        offer_type: constant.OFFER_TYPE[`${item.offer_type}`.toUpperCase()],
        vendor_id: item.vendor_id,
        period: item.disbursement_period,
      });
      const message = response.data?.data?.message;
      console.log(response)
      if (response.status == 201) {
        this.$swal('Success Generate', message, 'success');
      } else {
        this.$swal('Ops!', message, 'error');
      }
    }
  },
};
</script>
<style scoped>
.badge-purple {
  background-color: #ff06dc;
}
.badge-yellow {
  background-color: #ffec2e;
  color: black;
}
</style>
